
import { User } from '@/store/auth';
import { Auth, Chat } from '@/store/modules';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({ name: 'Message'})
export default class extends Vue {
  @Prop() message!: any;
  @Prop() classesCache?: { [key: string]: { name: string; url: string } };
  author: any = null as any;
  isMe = false;
  className = '';
  classUrl = '';

  get user() {
    return Auth.user as User;
  }

  async mounted() {
    this.author = await Chat.getMessageAuthor({ senderId: this.message.senderId });
    this.isMe = this.message.senderId === this.user.id || this.message.senderId === this.user.teacherId;

    if (this.message.classId) {
      const cached = this.classesCache && this.classesCache[this.message.classId];
      if (cached) {
        this.className = cached.name;
        this.classUrl = cached.url;
        return;
      }

      try {
        const response = await Vue.$axios.get(`/classes/${this.message.classId}`);
        this.className = response.data.name;
        this.classUrl = `/classes/${response.data.slug}`;
        this.$emit('classLoaded', { name: this.className, url: this.classUrl, id: this.message.classId });
      } catch (err: any) {
        console.warn(err.message);
      }
    }
  }
}
